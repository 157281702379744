<template>
    <div id="display-asset-content-level-component">
        <template v-if="show_asset">
            <div :style="`height: ${this.getAssetDisplayOption('imageHeight')}px;`" class="position-relative w-fit">
                <img
                    :src="image_asset"
                    :style="`height: ${getAssetDisplayOption('imageHeight')}px;`"
                    alt="asset"
                    class="mb-0"
                    @error="useFallbackImage()"
                />

                <div
                    :class="`${getClassForAsset('generic-shape')} global-shape`"
                    :style="`
                    height: ${getAssetDisplayOption('liquidHeight')}px;
                    width: ${getAssetDisplayOption('liquidWidth')}px;
                    bottom: ${getAssetDisplayOption('bottom')}px;
                `"
                >
                    <div :class="`w-100 border-level absolute ${getIdForAsset('border')}`" />
                    <div :class="`w-100 liquid-level absolute ${getIdForAsset('liquid')}`" />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { nextTick } from 'vue'
import useAsset from '@/mixin/useAsset'

export default {
    name: 'DisplayAssetContentLevelComponent',
    mixins: [useAsset],
    props: {
        asset: {
            type: Object,
            required: true,
        },
        asset_module: {
            type: Object,
            required: false,
        },
        index_model: {
            type: Number,
            required: false,
        },
        scale: {
            type: Number,
            required: false,
            default: 1,
        },
        colorless: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            image_asset: '',
            show_asset: false,
            is_use_fallback_image_asset_model: false,
            asset_display_option:{
                //tank_parallelepipedic
                0: {
                    imageHeight: 120,
                    liquidHeight: 92,
                    liquidWidth: 180,
                    bottom: 14,
                },
                //suiclean
                1: {
                    imageHeight: 160,
                    liquidHeight: 132,
                    liquidWidth: 112,
                    bottom: 4,
                },
                //delaval
                2: {
                    imageHeight: 180,
                    liquidHeight: 158,
                    liquidWidth: 114,
                    bottom: 4,
                },
                //unused
                3: {
                    imageHeight: 180,
                    liquidHeight: 158,
                    liquidWidth: 72,
                    bottom: 4,
                },
                //h2 vertical
                4: {
                    imageHeight: 120,
                    liquidHeight: 72,
                    liquidWidth: 198,
                    bottom: 36,
                },
                //tank_cylindrical_horizontal
                5: {
                    imageHeight: 100,
                    liquidHeight: 76,
                    liquidWidth: 168,
                    bottom: 12,
                },
                //silo
                6: {
                    imageHeight: 180,
                    liquidHeight: 154,
                    liquidWidth: 74,
                    bottom: 14,
                },
                //7: manomètre
                //h2 horizontal
                8: {
                    imageHeight: 120,
                    liquidHeight: 70,
                    liquidWidth: 196,
                    bottom: 36
                },
                //tank_cylindrical_vertical
                9: {
                    imageHeight: 180,
                    liquidHeight: 154,
                    liquidWidth: 80,
                    bottom: 12
                },
                //tank_spherical
                10: {
                    imageHeight: 120,
                    liquidHeight: 90,
                    liquidWidth: 90,
                    bottom: 18
                },
                //tank_cubic
                11: {
                    imageHeight: 120,
                    liquidHeight: 96,
                    liquidWidth: 96,
                    bottom: 11
                },
            },
        }
    },
    computed: {
        configuration() {
            return JSON.parse(this.asset.configuration)
        },

        configuration_datatable() {
            return JSON.parse(this.asset.configuration_datatable_asset_vision_data)
        },

        asset_model() {
            return this.$store.getters['asset_module/asset_model_by_id'](
                this.asset_module.id_module,
                this.configuration[this.index_model].id_asset_model
            )
        },

        is_configuration_valid() {
            return this.configuration && this.configuration[this.index_model] && this.configuration[this.index_model].is_required
        },
    },
    methods: {
        async useFallbackImage() {
            this.is_use_fallback_image_asset_model = true
            this.image_asset = '/images/asset/fallback_asset.png'
            this.setLiquidHeight()
        },

        getImageAssetToDisplay() {
            this.show_asset = false
            if (this.is_configuration_valid) {
                if (!this.asset_display_option[this.asset_model.front_model_reference]) {
                    this.useFallbackImage()
                } else {
                    this.image_asset = this.asset_model.path_detail_model

                    nextTick(() => {
                        this.setLiquidHeight()
                    })
                }
            }

            this.show_asset = true
        },

        setLiquidHeight() {
            let height = this.getEquivalentPercentHeightLiquidLevel()
            let color = this.getColorLiquid()

            Array.prototype.forEach.call(document.getElementsByClassName(this.getIdForAsset('liquid')), function (liquid) {
                liquid.style.height = height + 'px'
                liquid.style.background = color
            })

            Array.prototype.forEach.call(document.getElementsByClassName(this.getIdForAsset('border')), function (border) {
                border.style.bottom = height - 2 + 'px'
                border.style.background = color
            })
        },

        getColorLiquid() {
            if (this.colorless) {
                return '#9399a3'
            }

            if (this.is_configuration_valid) {
                return this.$store.getters['asset_module/liquid_by_id_module_and_id_asset_and_model'](
                    this.asset_module.id_module,
                    this.asset.id_asset,
                    this.index_model
                ).color
            }

            return this.$store.getters['display_option/display_option'].primary
        },

        getIdForAsset(prefix) {
            return `${this.scale}-${prefix}-${this.asset?.id_asset}-${this.index_model}`
        },

        getClassForAsset(prefix) {
            if (this.is_configuration_valid) {
                return `${prefix}-${this.is_use_fallback_image_asset_model ? 0 : this.asset_model.front_model_reference}`
            }
        },

        getAssetDisplayOption(key) {
            if (this.is_configuration_valid) {
                return (
                    this.asset_display_option[this.is_use_fallback_image_asset_model ? 0 : this.asset_model.front_model_reference][key] *
                    this.scale
                )
            }
        },

        getEquivalentPercentHeightLiquidLevel() {
            if (this.is_configuration_valid) {
                return (this.getConfigurationDatatableAssetVisionData(this.asset, this.index_model, 'percent') / 100) * this.getAssetDisplayOption('liquidHeight')
            }
        },
    },
    watch: {
        colorless: {
            handler() {
                this.setLiquidHeight()
            },
        },
    },
    created() {
        this.getImageAssetToDisplay()
    },
}
</script>

<style>
.global-shape {
    position: absolute;
    overflow: hidden;
    left: 50%;
    transform: translateX(-50%);
}

.liquid-level,
.border-level {
    transition: height, bottom, opacity !important;
    transition-duration: 0.7s !important;
    transition-timing-function: ease-in-out !important;
}

.liquid-level {
    height: 0;
    bottom: 0;
    opacity: 0.3;
}

.border-level {
    bottom: -4px;
}

.generic-shape-0 {
    border-radius: 4%;
}

.generic-shape-1 {
    border-radius: 8%;
}

.generic-shape-2 {
    border-radius: 0 0 25% 25%;
}

.generic-shape-3 {
    border-radius: 20px;
}

.generic-shape-4 {
    border-radius: 500px;
    transform: translateX(-45%) !important;
}

.generic-shape-5 {
    border-radius: 500px;
}

.generic-shape-6 {
    clip-path: polygon(30% 0%, 70% 0%, 100% 10%, 100% 86%, 60% 100%, 40% 100%, 0% 86%, 0% 10%);
}
</style>
