<template>
    <div id="asset-detail-component">
        <div v-if="!fetching_configuration && is_asset_detail_visible">
            <container
                v-for="n in widgets.length + (is_mobile ? 0 : 1)"
                :key="n"
                :get-child-payload="getWidget(n - 1)"
                :orientation="is_mobile ? 'vertical' : 'horizontal'"
                class="w-100 table-fixed"
                group-name="1"
                non-drag-area-selector=".no-drag"
                @drop="onDrop(n - 1, $event)"
            >
                <draggable
                    v-for="(widget, i) in widgets[n - 1]"
                    :key="i"
                    :style="`width: ${100 / (is_mobile ? 1 : widgets[n - 1].length)}% !important;`"
                    class="no-drag"
                >
                    <div class="ma-2">
                        <template v-if="widget.widget_name === 'alerts_configured_widget'">
                            <alerts-configured-widget-component :asset="asset" />
                        </template>

                        <template v-else-if="widget.widget_name === 'alerts_triggered_widget'">
                            <alerts-triggered-widget-component :asset="asset" />
                        </template>

                        <template v-else-if="widget.widget_name === 'asset_additional_fields_widget'">
                            <asset-additional-fields-widget-component :asset="asset" :asset_module="asset_module" />
                        </template>

                        <template v-else-if="widget.widget_name === 'asset_devices_information_widget'">
                            <asset-devices-information-widget-component :devices="asset.devices" />
                        </template>

                        <template v-else-if="widget.widget_name === 'asset_localisation_widget'">
                            <asset-localisation-widget-component :asset="asset" :asset_module="asset_module" />
                        </template>

                        <template v-else-if="widget.widget_name === 'asset_model_specifications_widget'">
                            <asset-model-specifications-widget-component :asset="asset" />
                        </template>

                        <template v-else-if="widget.widget_name.includes('chart_by_asset_metric_widget')">
                            <chart-by-asset-widget-component
                                :asset="asset"
                                :chart_configuration="charts_configuration.find((config) => widget.widget_name.includes(config.widget_key))"
                                :device="getDeviceByChartConfigurationAndWidget(widget)"
                            />
                        </template>

                        <template v-else-if="widget.widget_name === 'chart_by_metrics_widget'">
                            <chart-by-metrics-widget-component :asset="asset" :asset_module="asset_module" :device="asset.devices[0]" />
                        </template>

                        <template v-else-if="widget.widget_name === 'consumption_report_widget'">
                            <consumption-report-widget-component :asset="asset" :asset_module="asset_module" :device="asset.devices[0]" />
                        </template>

                        <template v-else-if="widget.widget_name.includes('device_traited_data_by_asset_metric_datatable_widget')">
                            <device-traited-data-by-asset-metric-datatable-widget-component
                                :asset="asset"
                                :device="getDeviceByMetricsConfigurationAndWidget(widget)"
                                :metrics_configuration="
                                    metrics_configuration.find((config) => widget.widget_name.includes(config.widget_key))
                                "
                            />
                        </template>

                        <template v-else-if="widget.widget_name === 'device_traited_data_by_device_metric_datatable_widget'">
                            <device-traited-data-by-device-metric-datatable-widget-component :device="asset.devices[0]" />
                        </template>

                        <template v-else-if="widget.widget_name === 'donut_chart_widget'">
                            <donut-chart-widget-component
                                :asset_module="asset_module"
                                :configuration_widget="widget.configuration"
                                :device="asset?.devices[0]"
                            />
                        </template>

                        <template v-else-if="widget.widget_name === 'image_asset_widget'">
                            <image-asset-widget-component :asset="asset" />
                        </template>

                        <template v-else-if="widget.widget_name === 'information_asset_widget'">
                            <boostherm-widget-component
                                v-if="asset_module.id_module === 54 || asset_module.id_module === 64"
                                :asset="asset"
                            />

                            <information-asset-widget-component v-else :asset="asset" :asset_module="asset_module" />
                        </template>

                        <template v-else-if="widget.widget_name === 'map_position_history_widget'">
                            <map-position-history-widget-component :device="asset.devices?.find(isDeviceGeolocalisation)" />
                        </template>

                        <template v-else-if="widget.widget_name === 'note_devices_widget'">
                            <note-devices-widget-component :devices="asset.devices" />
                        </template>
                    </div>
                </draggable>
            </container>
        </div>

        <template v-else>
            <v-row class="mt-8">
                <v-col v-for="n in 4" :key="n" cols="12" lg="6">
                    <v-skeleton-loader type="card-heading, list-item-two-line, image, table-thead" />
                </v-col>
            </v-row>
        </template>
    </div>
</template>

<script>
import { Container, Draggable } from 'vue-smooth-dnd'
import ImageAssetWidgetComponent from '@/components/Equipment/Generic/Widget/ImageAssetWidgetComponent.vue'
import InformationAssetWidgetComponent from '@/components/Equipment/Generic/Widget/InformationAssetWidgetComponent.vue'
import DeviceRepository from '@/repositories/DeviceRepository'
import ChartByAssetWidgetComponent from '@/components/Equipment/Generic/Widget/ChartByAssetWidgetComponent.vue'
import BoosthermWidgetComponent from '@/components/Equipment/Generic/Widget/BoosthermWidgetComponent.vue'
import AssetLocalisationWidgetComponent from '@/components/Equipment/Generic/Widget/AssetLocalisationWidgetComponent.vue'
import AssetDevicesInformationWidgetComponent from '@/components/Equipment/Generic/Widget/AssetDevicesInformationWidgetComponent.vue'
import AssetModelSpecificationsWidgetComponent from '@/components/Equipment/Generic/Widget/AssetModelSpecificationsWidgetComponent.vue'
import ChartByMetricsWidgetComponent from '@/components/Equipment/Generic/Widget/ChartByMetricsWidgetComponent.vue'
import DeviceTraitedDataByAssetMetricDatatableWidgetComponent from '@/components/Equipment/Generic/Widget/DeviceTraitedDataByAssetMetricDatatableWidgetComponent.vue'
import DeviceTraitedDataByDeviceMetricDatatableWidgetComponent from '@/components/Equipment/Generic/Widget/DeviceTraitedDataByDeviceMetricDatatableWidgetComponent.vue'
import MapPositionHistoryWidgetComponent from '@/components/Equipment/Generic/Widget/MapPositionHistoryWidgetComponent.vue'
import AlertsConfiguredWidgetComponent from '@/components/Equipment/Generic/Widget/AlertsConfiguredWidgetComponent.vue'
import ConsumptionReportWidgetComponent from '@/components/Equipment/Generic/Widget/ConsumptionReportWidgetComponent.vue'
import AlertsTriggeredWidgetComponent from '@/components/Equipment/Generic/Widget/AlertsTriggeredWidgetComponent.vue'
import NoteDevicesWidgetComponent from '@/components/Equipment/Generic/Widget/NoteDevicesWidgetComponent.vue'
import AssetAdditionalFieldsWidgetComponent from '@/components/Equipment/Generic/Widget/AssetAdditonalFieldsWidgetComponent.vue'
import DonutChartWidgetComponent from '@/components/Equipment/Generic/Widget/DonutChartWidgetComponent.vue'
import { nextTick } from 'vue'

export default {
    name: 'AssetDetailComponent',
    components: {
        DonutChartWidgetComponent,
        AssetAdditionalFieldsWidgetComponent,
        NoteDevicesWidgetComponent,
        AlertsTriggeredWidgetComponent,
        AlertsConfiguredWidgetComponent,
        MapPositionHistoryWidgetComponent,
        DeviceTraitedDataByDeviceMetricDatatableWidgetComponent,
        DeviceTraitedDataByAssetMetricDatatableWidgetComponent,
        ConsumptionReportWidgetComponent,
        ChartByMetricsWidgetComponent,
        AssetModelSpecificationsWidgetComponent,
        AssetDevicesInformationWidgetComponent,
        AssetLocalisationWidgetComponent,
        BoosthermWidgetComponent,
        ChartByAssetWidgetComponent,
        InformationAssetWidgetComponent,
        ImageAssetWidgetComponent,
        Container,
        Draggable,
    },
    props: {
        asset: {
            type: Object,
            required: true,
        },
        asset_module: {
            type: Object,
            required: true,
        },
    },
    async created() {
        this.fetching_configuration = true
        if (this.asset?.devices) {
            //Fetch chart & metrics configuraiton
            await Promise.all([this.fetchAssetChartsConfiguration(), this.fetchAssetMetricsConfiguration()])
        }

        //Build widget organization object
        this.asset_module.sens_widgets_configuration.forEach((item) => {
            if (this.widgets[item.col - 1]) {
                this.widgets[item.col - 1].push(item)
            } else {
                this.widgets[item.col - 1] = [item]
            }
        })

        this.fetching_configuration = false
    },
    data() {
        return {
            fetching_configuration: true,
            is_asset_detail_visible: true,
            widgets: [],
            charts_configuration: [],
            metrics_configuration: [],
        }
    },
    methods: {
        onDrop(collection, { removedIndex, addedIndex, payload }) {
            if (removedIndex !== null) {
                this.widgets[collection].splice(removedIndex, 1)[0]
            }

            if (addedIndex !== null) {
                if (this.widgets[collection]) {
                    this.widgets[collection].splice(addedIndex, 0, payload)
                } else {
                    this.widgets[collection] = [payload]
                }
            }
        },

        getWidget(row) {
            return (index) => {
                return this.widgets[row][index]
            }
        },

        fetchAssetChartsConfiguration() {
            this.charts_configuration = []
            return Promise.all(
                this.asset.devices.map((device) => {
                    return Promise.resolve(this.fetchAssetChartsConfigurationByDevice(device))
                })
            ).catch((error) => {
                this.manageError(error)
            })
        },

        fetchAssetChartsConfigurationByDevice(device) {
            return DeviceRepository.getAssetChartsConfigurations(device.device_number).then((success) => {
                success.data.data.asset_charts_configurations.forEach((asset_charts_configuration) => {
                    this.charts_configuration.push(asset_charts_configuration)
                })
            })
        },

        fetchAssetMetricsConfiguration() {
            this.metrics_configuration = []
            return Promise.all(
                this.asset.devices.map((device) => {
                    return Promise.resolve(this.fetchAssetMetricsConfigurationByDevice(device))
                })
            ).catch((error) => {
                this.manageError(error)
            })
        },

        fetchAssetMetricsConfigurationByDevice(device) {
            return DeviceRepository.getAssetMetricsConfigurations(device.device_number).then((success) => {
                success.data.data.asset_metrics_configurations.forEach((asset_charts_configuration) => {
                    this.metrics_configuration.push(asset_charts_configuration)
                })
            })
        },

        getDeviceByChartConfigurationAndWidget(widget) {
            if (this.asset.devices) {
                return this.asset.devices.length === 1
                    ? this.asset.devices[0]
                    : this.asset.devices.find(
                          (device) =>
                              device.id_device ===
                              this.charts_configuration.find((config) => widget.widget_name.includes(config.widget_key)).id_device
                      )
            }

            return null
        },

        getDeviceByMetricsConfigurationAndWidget(widget) {
            if (this.asset?.devices) {
                return this.asset.devices.length === 1
                    ? this.asset.devices[0]
                    : this.asset.devices.find(
                          (device) =>
                              device.id_device ===
                              this.metrics_configuration.find((config) => widget.widget_name.includes(config.widget_key)).id_device
                      )
            }

            return null
        },
    },
    watch: {
        is_mobile() {
            this.is_asset_detail_visible = false
            nextTick(() => {
                this.is_asset_detail_visible = true
            })
        },
    },
}
</script>
