import moment from 'moment/moment'

export default {
    data() {
        return {
            max_series: 4,
            is_chart_series_display: [true, true, true, true],
            chart_series_data: [[], [], [], []],
            chart_series_colors: [this.$store.getters['display_option/display_option'].primary, '#603821', '#5ebe12', '#21b4c0'],

            options: {
                title: {
                    text: '',
                },
                time: {
                    //Add 2 hours to timestamp
                    timezoneOffset: new Date().getTimezoneOffset(),
                },
                exporting: {
                    accessibility: {
                        enabled: true,
                    },
                    menuItemDefinitions: {
                        downloadPNG: {
                            text: 'Télécharger au format PNG',
                        },
                        downloadPDF: {
                            text: 'Télécharger au format PDF',
                        },
                    },
                    buttons: {
                        contextButton: {
                            menuItems: ['downloadPNG', 'downloadPDF'],
                        },
                    },
                    enabled: true,
                    filename: 'text_filename',
                },
                legend: {
                    enabled: false,
                },
                navigator: {
                    enabled: true,
                },
                plotOptions: {
                    flags: {
                        style: { fontSize: '13px' },
                    },
                    column: {
                        pointPadding: 10,
                        pointWidth: 20,
                    },
                    line: {
                        lineWidth: 2,
                    },
                },
                series: [
                    {
                        name: 'serie_1',
                        yAxis: 0,
                        data: [],
                        color: 'chartSeriesColors.value[0]',
                        type: 'line',
                        showInNavigator: true,
                    },
                ],
                xAxis: {
                    type: 'datetime',
                    labels: {
                        formatter: function () {
                            return moment(this.value).format('DD MMM')
                        },
                    },
                },
                yAxis: [
                    {
                        title: {
                            text: '',
                        },
                        //https://api.highcharts.com/highcharts/yAxis.plotLines
                        dateTimeLabelFormats: 'DD MMM',
                    },
                ],
                tooltip: {
                    xDateFormat: '%d/%m/%Y %H:%M:%S',
                },
            },
        }
    },
    methods: {
        resetChartData() {
            this.chart_series_data = [[], [], [], []]
        },

        fillChartSeries() {
            for (let index = 0; index < this.max_series; index++) {
                this.options.series[index].data = []
                if(this.is_chart_series_display[index]) {
                    this.options.series[index].data = this.chart_series_data[index]
                }
            }
        },

        /**
         * Create chart series
         */
        setSeries(series) {
            this.options.series = []
            this.max_series = series.length

            series.forEach((serie, index) => {
                this.options.series.push({
                    name: `${serie.label} (${serie.unity})`,
                    data: [],
                    yAxis: serie.y_axis,
                    type: serie.chart_type.toLowerCase(),
                    color: serie.color,
                    // dashStyle: 'longdash',
                    id: JSON.stringify(index),
                    showInNavigator: true,
                })
            })
        },

        /**
         * Create chart axis with their plotLines & plotBands
         */
        setAxis(axis) {
            if (axis.length > 0) {
                this.options.yAxis = []
                axis.forEach((axe) => {
                    /* PLOTLINES */
                    let plotLines = []
                    axe.plotLines?.forEach((plotLine) => {
                        plotLines.push({
                            value: plotLine.value,
                            color: plotLine.color,
                            dashStyle: plotLine.style, //line, shortdash, dash
                            width: 2,
                            label: {
                                text: plotLine.label,
                                align: 'left', //left, center, right
                            },
                        })
                    })

                    /* PLOTBANDS */
                    let plotBands = []
                    axe.plotBands?.forEach((plotBand) => {
                        plotBands.push({
                            to: plotBand.to,
                            from: plotBand.from,
                            color: plotBand.color, //line, shortdash, dash
                        })
                    })

                    /* AXIS */
                    this.options.yAxis.push({
                        title: {
                            text: `${axe.title} (${axe.unit})`,
                        },
                        min: axe.range[0],
                        max: axe.range[1],
                        height: axe.height,
                        opposite: axe.opposite,
                        top: axe.top,
                        plotBands: plotBands,
                        plotLines: plotLines,
                    })
                })
            }
        },

        /**
         * Set all flags on chart
         * Must be call after setSeries
         */
        setEvents(events) {
            events.forEach((event) => {
                let flags = []
                event.flags.forEach((flag) => {
                    flags.push({
                        x: flag.value,
                        title: flag.title,
                        color: flag.color,
                        text: flag.text,
                        type: flag.type,
                        attributes: flag.attributes
                    })
                })

                this.options.series.push({
                    type: 'flags',
                    data: flags,
                    onSeries: event.id_series,
                    shape: 'circlepin',
                })
            })
        }
    },
}
